

























import { Component } from 'vue-property-decorator';
import PageBannerHeader from '@/components/PageBannerHeader.vue'
import DataTable from '@/components/DataTable.vue'
import CsvVuexBackedTableBasedView from '@/views/tables/CsvVuexBackedTableBasedView'
import { CabinetSensorReadingEvent } from '@/api/devices'
import { ACTIONS, GETTERS } from '@/store/modules/reports/drying-unit-reports'
import TableFooter from '@/components/TableFooter.vue'
import DeviceCabinetIcon from '@/assets/img/device-cabinet-icon.svg'
import { TableColumn } from '@/components/table-column'
import DownloadButton from '@/components/DownloadButton.vue'
import SingleselectWrapper from '@/components/forms/SingleselectWrapper.vue'
import MultiselectWrapper from '@/components/forms/MultiselectWrapper.vue'
import DatepickerWrapper from '@/components/forms/Datepicker.vue'
import { getLocaleStringFromIsoDate, convertToUuidArray } from '@/util'

@Component({
  components: {
    PageBannerHeader,
    DataTable,
    TableFooter,
    DeviceCabinetIcon,
    SingleselectWrapper,
    MultiselectWrapper,
    DatepickerWrapper,
    DownloadButton,
  }
})
export default class MaintenanceLogReportTable extends CsvVuexBackedTableBasedView<CabinetSensorReadingEvent> {
  GETTERS = GETTERS
  ACTIONS = ACTIONS

  clientIds: string[] = []
  proxyIds: string[] = []

  async mounted(): Promise<void> {
    this.fetchAction = this.ACTIONS.FETCH_LIST
    this.reportName = 'Drying Unit Sensor Report.csv'

    // Try to filter by clientIds or proxyIds if they're provided, otherwise the backend falls to the devices the user has access to
    this.clientIds = convertToUuidArray(this.$route.query.clientId)
    this.proxyIds = convertToUuidArray(this.$route.query.proxyId)

    const clientFilterIds = this.clientIds.length > 0 ? this.clientIds : this.proxyIds
    await this.loadData(clientFilterIds)
  }

  get columns(): TableColumn<CabinetSensorReadingEvent>[] {
    return [
      {
        id: 'deviceName',
        title: 'Device Name',
        value: (row: CabinetSensorReadingEvent) => row.device.name,
        field: 'device.name',
        cellClass: 'selectable',
        headerClass: 'columnMedium',
      },
      {
        id: 'deviceId',
        title: 'Device ID',
        value: (row: CabinetSensorReadingEvent) => row.device.serialNumber,
        field: 'device.serialNumber',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'eventTime',
        title: 'Event Time',
        value: (row: CabinetSensorReadingEvent) => getLocaleStringFromIsoDate(row.timestamp),
        field: 'cabinet_sensor_reading.timestamp',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'averageTemperature',
        title: 'Average Temperature',
        value: (row: CabinetSensorReadingEvent) => this.formatTemperatureReading(row.averageTemperature),
        field: 'cabinet_sensor_reading.average_temperature',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'averageHumidity',
        title: 'Average Humidity',
        value: (row: CabinetSensorReadingEvent) => this.formatHumidityReading(row.averageHumidity),
        field: 'cabinet_sensor_reading.average_humidity',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
    ]
  }

  private formatTemperatureReading(value: number | null): string {
    if (value !== null) {
      const f = value * 9 / 5 + 32
      return `${f.toFixed(2)} °F (${value.toFixed(2)} °C)`
    }
    return ''
  }

  private formatHumidityReading(value: number | null): string {
    if (value !== null) {
      return value.toFixed(2) + '%'
    }
    return ''
  }
}
