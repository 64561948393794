








































import { Component } from 'vue-property-decorator'
import VuexBackedTableBasedView from '@/views/tables/VuexBackedTableBasedView'
import TableFooter from '@/components/TableFooter.vue'
import { TableColumn } from '@/components/table-column'
import { ACTIONS, GETTERS } from '@/store/modules/notification-management'
import Modal from '@/components/Modal.vue'
import EditButton from '@/components/EditButton.vue'
import {
  NotificationAlertTypeDescriptionMap,
  NotificationPreference,
  NotificationPreferenceUpdate
} from '@/api/notifications'
import Toggle from '@/components/Toggle.vue'
import SingleselectWrapper from '@/components/forms/SingleselectWrapper.vue'
import NotificationIcon from '@/assets/img/notifications-icon.svg'

@Component({
  components: {
    TableFooter,
    Modal,
    EditButton,
    Toggle,
    SingleselectWrapper,
    NotificationIcon
  }
})
export default class NotificationTable extends VuexBackedTableBasedView<NotificationPreference> {
  ACTIONS = ACTIONS
  GETTERS = GETTERS

  destroyed(): void {
    this.$store.dispatch(ACTIONS.CLEAR)
  }

  get columns(): TableColumn<NotificationPreference>[] {
    return [
      {
        title: 'Alert Type',
        value: (row: NotificationPreference) => row.alertType,
        headerColumn: true,
        headerClass: 'columnMedium',
        sticky: true,
      },
      {
        title: 'Description',
        value: (row: NotificationPreference) => NotificationAlertTypeDescriptionMap[row.alertType],
        headerClass: 'columnMedium'
      },
      {
        title: 'Subscribed',
        value: () => '',
        headerClass: 'columnSmall'
      },
      {
        title: 'Interval',
        value: () => '',
        headerClass: 'columnSmall',
        cellClass: 'selectable'
      },
    ]
  }

  async updateNotificationPreference(enabled: boolean, notificationPreference: NotificationPreference, interval: string | undefined): Promise<void> {
    // If notification interval is defined we may be updating a interval value
    // the backend has a unique constraint on alert_type + user_id so we will remove
    // the entry before readding it (update)
    if (interval) {
      await this.$store.dispatch(
        ACTIONS.UPDATE_PREFERENCE,
        { alertType: notificationPreference.alertType, enabled: false } as NotificationPreferenceUpdate
      )
    }
    if (notificationPreference.alertType === 'Expiring Scope' && enabled && !interval){
      interval='24'
    }

    const newValues = await this.$store.dispatch(
      ACTIONS.UPDATE_PREFERENCE,
      { alertType: notificationPreference.alertType, enabled, interval } as NotificationPreferenceUpdate
    )
    notificationPreference.enabled = newValues.enabled
    notificationPreference.interval = newValues.interval
  }
}
