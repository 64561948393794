export const PROXY_ID = 'proxyId'

export const PAGE_NAMES = {
  // Standard Pages
  DASHBOARD: 'Dashboard',
  REPORTS: 'Reports',
  USER_MANAGEMENT: 'User Management',
  NOTIFICATIONS: 'Notification Management',
  MANAGE_DEVICES: 'Manage Devices',
  ORDER_PARTS: 'Order Parts',
  // Standard Pages - Cabinets
  DRYING_UNIT_REPORTS: 'Drying Unit Reports',
  DRYING_UNIT_DASHBOARD: 'Drying Unit Dashboard',
  WEBSITE_USERS: 'Website Users',
  CABINET_DEVICES: 'Cabinet Devices',
  NOTIFICATIONS_CABINETS: 'Cabinet Notifications',
  // Standard Pages - Locks
  LOCK_DEVICES: 'Lock Devices',
  DEVICE_USERS: 'Device Users',
  SMART_LOCK_REPORTS: 'InterConnect Lock Reports',
  SMART_LOCK_DASHBOARD: 'InterConnect Lock Dashboard',
  NOTIFICATIONS_LOCKS: 'Lock Notifications',
  // Admin-only screens
  CLIENT_SUMMARY_DASHBOARD: 'Client Summary Dashboard',
  CLIENT_MANAGEMENT: 'Client Management',
  CLIENT_DATA: 'Client Data',
  CLIENT_DEVICES: 'Client Devices',
  CLIENT_SUBSCRIPTIONS: 'Client Subscriptions',
  // Misc
  UNAUTHORIZED: 'Unauthorized',
  NOT_FOUND: 'Not Found',
}

export const stateListDict = [
  { abbreviation: "AL", name: "Alabama" },
  { abbreviation: "AK", name: "Alaska" },
  { abbreviation: "AS", name: "American Samoa" },
  { abbreviation: "AZ", name: "Arizona" },
  { abbreviation: "AR", name: "Arkansas" },
  { abbreviation: "CA", name: "California" },
  { abbreviation: "CO", name: "Colorado" },
  { abbreviation: "CT", name: "Connecticut" },
  { abbreviation: "DE", name: "Delaware" },
  { abbreviation: "DC", name: "District Of Columbia" },
  { abbreviation: "FM", name: "Federated States Of Micronesia" },
  { abbreviation: "FL", name: "Florida" },
  { abbreviation: "GA", name: "Georgia" },
  { abbreviation: "GU", name: "Guam" },
  { abbreviation: "HI", name: "Hawaii" },
  { abbreviation: "ID", name: "Idaho" },
  { abbreviation: "IL", name: "Illinois" },
  { abbreviation: "IN", name: "Indiana" },
  { abbreviation: "IA", name: "Iowa" },
  { abbreviation: "KS", name: "Kansas" },
  { abbreviation: "KY", name: "Kentucky" },
  { abbreviation: "LA", name: "Louisiana" },
  { abbreviation: "ME", name: "Maine" },
  { abbreviation: "MH", name: "Marshall Islands" },
  { abbreviation: "MD", name: "Maryland" },
  { abbreviation: "MA", name: "Massachusetts" },
  { abbreviation: "MI", name: "Michigan" },
  { abbreviation: "MN", name: "Minnesota" },
  { abbreviation: "MS", name: "Mississippi" },
  { abbreviation: "MO", name: "Missouri" },
  { abbreviation: "MT", name: "Montana" },
  { abbreviation: "NE", name: "Nebraska" },
  { abbreviation: "NV", name: "Nevada" },
  { abbreviation: "NH", name: "New Hampshire" },
  { abbreviation: "NJ", name: "New Jersey" },
  { abbreviation: "NM", name: "New Mexico" },
  { abbreviation: "NY", name: "New York" },
  { abbreviation: "NC", name: "North Carolina" },
  { abbreviation: "ND", name: "North Dakota" },
  { abbreviation: "MP", name: "Northern Mariana Islands" },
  { abbreviation: "OH", name: "Ohio" },
  { abbreviation: "OK", name: "Oklahoma" },
  { abbreviation: "OR", name: "Oregon" },
  { abbreviation: "PW", name: "Palau" },
  { abbreviation: "PA", name: "Pennsylvania" },
  { abbreviation: "PR", name: "Puerto Rico" },
  { abbreviation: "RI", name: "Rhode Island" },
  { abbreviation: "SC", name: "South Carolina" },
  { abbreviation: "SD", name: "South Dakota" },
  { abbreviation: "TN", name: "Tennessee" },
  { abbreviation: "TX", name: "Texas" },
  { abbreviation: "UT", name: "Utah" },
  { abbreviation: "VT", name: "Vermont" },
  { abbreviation: "VI", name: "Virgin Islands" },
  { abbreviation: "VA", name: "Virginia" },
  { abbreviation: "WA", name: "Washington" },
  { abbreviation: "WV", name: "West Virginia" },
  { abbreviation: "WI", name: "Wisconsin" },
  { abbreviation: "WY", name: "Wyoming" }
]
