import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Amplify from '@aws-amplify/core'
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';
import { init }  from '@amplitude/analytics-browser';

applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.config.ignoredElements = [/^amplify-/];
Vue.config.productionTip = false

Amplify.configure({
  Auth: {
    region: 'us-east-1',
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    authenticationFlowType: 'USER_SRP_AUTH',
    endpoint: 'https://cognito-idp-fips.us-east-1.amazonaws.com/'
  },
})

Vue.filter('formatDate', (datetime: string) => {
  if (!datetime) {
    return ''
  }

  const date = new Date(datetime)
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
})

Vue.filter('formatTime', (datetime: string) => {
  if (!datetime) {
    return ''
  }

  const date = new Date(datetime)
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

init("b4b2de9d1a3b94653ce56a0a8263b33f");