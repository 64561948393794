












import { Component, Vue } from 'vue-property-decorator'
import PageBannerHeader from '@/components/PageBannerHeader.vue'
import { PAGE_NAMES } from '@/api/misc'
import DeviceCabinetIcon from "@/assets/img/device-cabinet-icon.svg"
import InterConnectLockIcon from "@/assets/img/inter-connect-lock-icon.svg"

@Component({
  components: {
    PageBannerHeader,
    DeviceCabinetIcon,
    InterConnectLockIcon,
  }
})
export default class Home extends Vue {
  PAGE_NAMES = PAGE_NAMES;
}

