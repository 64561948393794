

























import { Component } from 'vue-property-decorator';
import PageBannerHeader from '@/components/PageBannerHeader.vue'
import DataTable from '@/components/DataTable.vue'
import CsvVuexBackedTableBasedView from '@/views/tables/CsvVuexBackedTableBasedView'
import { CabinetMaintenanceLogEvent, MAINTENANCE_ACTION_KEYS } from '@/api/devices'
import { ACTIONS, GETTERS } from '@/store/modules/reports/drying-unit-reports'
import TableFooter from '@/components/TableFooter.vue'
import DeviceCabinetIcon from '@/assets/img/device-cabinet-icon.svg'
import { TableColumn } from '@/components/table-column'
import DownloadButton from '@/components/DownloadButton.vue'
import SingleselectWrapper from '@/components/forms/SingleselectWrapper.vue'
import MultiselectWrapper from '@/components/forms/MultiselectWrapper.vue'
import DatepickerWrapper from '@/components/forms/Datepicker.vue'
import { getLocaleStringFromIsoDate, Optional, convertToUuidArray } from '@/util'

@Component({
  components: {
    PageBannerHeader,
    DataTable,
    TableFooter,
    DeviceCabinetIcon,
    SingleselectWrapper,
    MultiselectWrapper,
    DatepickerWrapper,
    DownloadButton,
  }
})
export default class MaintenanceLogReportTable extends CsvVuexBackedTableBasedView<CabinetMaintenanceLogEvent> {
  GETTERS = GETTERS
  ACTIONS = ACTIONS

  clientIds: string[] = []
  proxyIds: string[] = []

  async mounted(): Promise<void> {
    this.fetchAction = this.ACTIONS.FETCH_LIST
    this.reportName = 'Drying Unit Maintenance Report.csv'

    // Try to filter by clientIds or proxyIds if they're provided, otherwise the backend falls to the devices the user has access to
    this.clientIds = convertToUuidArray(this.$route.query.clientId)
    this.proxyIds = convertToUuidArray(this.$route.query.proxyId)

    const clientFilterIds = this.clientIds.length > 0 ? this.clientIds : this.proxyIds
    await this.loadData(clientFilterIds)
  }

  get columns(): TableColumn<CabinetMaintenanceLogEvent>[] {
    return [
      {
        id: 'deviceName',
        title: 'Device Name',
        headerColumn: true,
        sticky: true,
        value: (row: CabinetMaintenanceLogEvent) => row.device.name,
        field: 'device.name',
        cellClass: 'selectable',
        headerClass: 'columnMedium',
      },
      {
        id: 'deviceId',
        title: 'Device ID',
        value: (row: CabinetMaintenanceLogEvent) => row.device.serialNumber,
        field: 'device.serialNumber',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'department',
        title: 'Department',
        value: (row: CabinetMaintenanceLogEvent) => row.device.department.name,
        field: 'department.name',
        cellClass: 'selectable',
        headerClass: 'columnMedium',
      },
      {
        id: 'location',
        title: 'Location',
        value: (row: CabinetMaintenanceLogEvent) => row.device.location ?? '',
        field: 'device.location',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'event',
        title: 'Event',
        value: (row: CabinetMaintenanceLogEvent) => MAINTENANCE_ACTION_KEYS[row.action] ?? '',
        field: 'cabinet_maintenance.action',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'eventTime',
        title: 'Event Time',
        value: (row: CabinetMaintenanceLogEvent) => getLocaleStringFromIsoDate(row.timestamp),
        field: 'cabinet_maintenance.timestamp',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'previousValue',
        title: 'Previous Value',
        value: (row: CabinetMaintenanceLogEvent) => this.formattedValue(row.action, row.oldValue),
        field: 'cabinet_maintenance.old_value',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'currentValue',
        title: 'Current Value',
        value: (row: CabinetMaintenanceLogEvent) => this.formattedValue(row.action, row.newValue),
        field: 'cabinet_maintenance.new_value',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
    ]
  }

  private formattedValue(action: string, value: Optional<string>): string {
    const actionKeyValue = MAINTENANCE_ACTION_KEYS[action]
    switch (actionKeyValue) {
      case MAINTENANCE_ACTION_KEYS.dryingTimeChanged:
      case MAINTENANCE_ACTION_KEYS.ventingTimeChanged:
      case MAINTENANCE_ACTION_KEYS.expirationTimeChanged:
      case MAINTENANCE_ACTION_KEYS.ventIntervalChanged:
      {
        const parsedValue = parseInt(value ?? '')
        if (!isNaN(parsedValue)) {
          const hours = Math.floor(parsedValue / 60 / 60);
          const minutes = Math.floor(parsedValue / 60) - (hours * 60);
          const seconds = parsedValue % 60;
          // Return formatted hh:mm:ss
          return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
        }
        return value ?? '' // Fallback to whatever value exists if we couldn't parse
      }
      default:
        return value ?? ''
    }
  }
}
