





















import { Component, Vue } from 'vue-property-decorator';
import Auth from '@aws-amplify/auth'

@Component
export default class SignInForm extends Vue {

  autoLogin = true;

  mounted(): void{
    setTimeout(() => {
      if(this.autoLogin){
        Auth.signIn('mrlnholford@gmail.com', '435e06D3-dd1d-4826-966a-549df2eea2dd')
      }
    }, 2000)
  }

  authOff(): void {
    this.autoLogin = false;
  }
}
