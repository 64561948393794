
















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class PageBannerHeader extends Vue {
  @Prop({ required: false, default: 'Page Title'}) title?: string

  hasSecondaryActions = false

  mounted(): void {
    this.hasSecondaryActions = !!this.$slots.secondaryActions
  }

  updated(): void {
    this.hasSecondaryActions = !!this.$slots.secondaryActions
  }
}
