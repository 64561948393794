

























import { Component } from 'vue-property-decorator'
import PageBannerHeader from '@/components/PageBannerHeader.vue'
import DataTable from '@/components/DataTable.vue'
import CsvVuexBackedTableBasedView from '@/views/tables/CsvVuexBackedTableBasedView'
import { CabinetTransaction} from '@/api/devices'
import { ACTIONS, GETTERS} from '@/store/modules/reports/drying-unit-reports'
import TableFooter from '@/components/TableFooter.vue'
import DeviceCabinetIcon from '@/assets/img/device-cabinet-icon.svg'
import { TableColumn } from '@/components/table-column'
import DownloadButton from '@/components/DownloadButton.vue'
import { convertToUuidArray } from '@/util'
import SingleselectWrapper from '@/components/forms/SingleselectWrapper.vue'
import MultiselectWrapper from '@/components/forms/MultiselectWrapper.vue'
import DatepickerWrapper from '@/components/forms/Datepicker.vue'

@Component({
  components: {
    PageBannerHeader,
    DataTable,
    TableFooter,
    DeviceCabinetIcon,
    SingleselectWrapper,
    MultiselectWrapper,
    DatepickerWrapper,
    DownloadButton,
  }
})
export default class ScopeActivityReportTable extends CsvVuexBackedTableBasedView<CabinetTransaction> {
  GETTERS = GETTERS
  ACTIONS = ACTIONS

  clientIds: string[] = []
  proxyIds: string[] = []

  async mounted(): Promise<void> {
    this.fetchAction = this.ACTIONS.FETCH_LIST
    this.reportName = 'Scope Transactions Report.csv'

    // Try to filter by clientIds or proxyIds if they're provided, otherwise the backend falls to the devices the user has access to
    this.clientIds = convertToUuidArray(this.$route.query.clientId)
    this.proxyIds = convertToUuidArray(this.$route.query.proxyId)

    const clientFilterIds = this.clientIds.length > 0 ? this.clientIds : this.proxyIds
    await this.loadData(clientFilterIds)
  }

  get columns(): TableColumn<CabinetTransaction>[] {
    const baseColumns: TableColumn<CabinetTransaction>[] = [
      {
        id: 'timestamp',
        title: 'Event Timestamp',
        value: (row: CabinetTransaction) => row.timestamp ? new Date(row.timestamp).toLocaleString() : '',
        field: 'cabinet_transaction.timestamp',
        cellClass: 'selectable',
        headerClass: 'columnMedium'
      },
      {
        id: 'action',
        title: 'Action',
        value: (row: CabinetTransaction) => row.action,
        field: 'cabinet_transaction.action',
        cellClass: 'selectable',
        headerClass: 'columnMedium'
      },
      {
        id: 'serialNumber',
        title: 'Serial Number',
        value: (row: CabinetTransaction) => row.scope.serialNumber ?? row.scope.serialNumber ?? '',
        field: 'cabinet_transaction.serial_number',
        cellClass: 'selectable',
        headerClass: 'columnMedium'
      },
      {
        id: 'slot',
        title: 'Slot',
        value: (row: CabinetTransaction) => row.slot.toString(),
        field: 'cabinet_transaction.slot',
        cellClass: 'selectable',
        headerClass: 'columnMedium'
      },
      {
        id: 'user',
        title: 'User',
        value: (row: CabinetTransaction) => row.user ?? '',
        field: 'cabinet_transaction.user',
        cellClass: 'selectable',
        headerClass: 'columnMedium'
      },
      {
        id: 'deviceName',
        title: 'Device Name',
        value: (row: CabinetTransaction) => row.device?.name ?? '',
        field: 'device.name',
        cellClass: 'selectable',
        headerClass: 'columnMedium',
      }
    ]

    let customColumns: TableColumn<CabinetTransaction>[] = this.results.flatMap(cabinetTransaction => {
      const fieldEntries: [string, string][] = Object.entries(cabinetTransaction.customFieldEntries ?? {})
      return fieldEntries.map(([key]) =>
        ({
          title: `${key}`,
          value: (row: CabinetTransaction) => row.customFieldEntries ? row.customFieldEntries[key] : '',
          field: `cabinet_transaction.custom_field_entries.${key}`,
          cellClass: 'selectable',
          headerClass: 'columnSmall',
        })
      )
    })
    // Get list of titles
    const titles = customColumns.map(column => column.title)
    // Reassign array with duplicate columns removed
    customColumns = customColumns.filter(({ title }, index) => !titles.includes(title, index + 1))

    return baseColumns.concat(customColumns)
  }
}
