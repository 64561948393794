































































































import { Component } from 'vue-property-decorator'
import { PAGE_NAMES } from '@/api/misc'
import ExitClientViewButton from '@/components/ExitClientViewButton.vue'
import RoleBasedView from '@/views/RoleBasedView'
import DashboardIcon from '@/assets/img/dashboard-icon.svg'
import ReportsIcon from '@/assets/img/reports-icon.svg'
import OrderPartsIcon from '@/assets/img/order-parts-icon.svg'
import SettingsIcon from '@/assets/img/settings-icon.svg'


@Component({
  components: {
    ExitClientViewButton,
    DashboardIcon,
    ReportsIcon,
    OrderPartsIcon,
    SettingsIcon,
  }
})
export default class SideNavBar extends RoleBasedView {
  PAGE_NAMES = PAGE_NAMES
  isProd = process.env.NODE_ENV === 'production'

  dashboardPages = [PAGE_NAMES.DASHBOARD, PAGE_NAMES.DRYING_UNIT_DASHBOARD, PAGE_NAMES.SMART_LOCK_DASHBOARD]

  reportPages = [PAGE_NAMES.REPORTS, PAGE_NAMES.DRYING_UNIT_REPORTS, PAGE_NAMES.SMART_LOCK_REPORTS]

  clientManagementPages = [PAGE_NAMES.CLIENT_MANAGEMENT, PAGE_NAMES.CLIENT_DATA, PAGE_NAMES.CLIENT_DEVICES, PAGE_NAMES.CLIENT_SUBSCRIPTIONS]
  userManagementPages = [PAGE_NAMES.USER_MANAGEMENT, PAGE_NAMES.WEBSITE_USERS, PAGE_NAMES.DEVICE_USERS]
  deviceManagementPages = [PAGE_NAMES.MANAGE_DEVICES, PAGE_NAMES.CABINET_DEVICES, PAGE_NAMES.LOCK_DEVICES]
  notificationManagementPages = [PAGE_NAMES.NOTIFICATIONS, PAGE_NAMES.NOTIFICATIONS_CABINETS, PAGE_NAMES.NOTIFICATIONS_LOCKS]
  settingsManagementPages = this.clientManagementPages.concat(this.userManagementPages, this.deviceManagementPages, this.notificationManagementPages)

  get settingsTopLevelNav(): string {
    return (this.isAdminImpersonating || this.isHospitalAdmin) ? PAGE_NAMES.MANAGE_DEVICES : PAGE_NAMES.CLIENT_MANAGEMENT
  }
}
