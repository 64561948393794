










import { Component } from 'vue-property-decorator'
import { PAGE_NAMES } from '@/api/misc'
import Vue from 'vue'
import PageBannerHeader from '@/components/PageBannerHeader.vue'
import DeviceCabinetIcon from '@/assets/img/device-cabinet-icon.svg'
import InterConnectLockIcon from '@/assets/img/inter-connect-lock-icon.svg'

@Component({
  components: {
    PageBannerHeader,
    DeviceCabinetIcon,
    InterConnectLockIcon
  }
})
export default class NotificationManagement extends Vue {
  PAGE_NAMES = PAGE_NAMES
}
