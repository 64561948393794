













import { Component, Vue } from 'vue-property-decorator'
import PageBannerHeader from '@/components/PageBannerHeader.vue'
import Modal from '@/components/Modal.vue'
import { MODAL_ACTIONS, MODAL_TYPE } from '@/store/types'
import DeviceWizard from '@/components/forms/DeviceWizard.vue'
import DevicesTable from '@/views/manage-devices/tables/DevicesTable.vue'
import { PAGE_NAMES } from '@/api/misc'

@Component({
  components: {
    DeviceWizard,
    PageBannerHeader,
    Modal,
    DevicesTable
  }
})
export default class ManageDevices extends Vue {
  MODAL_TYPE = MODAL_TYPE
  PAGE_NAMES = PAGE_NAMES

  addDevice(): void {
    this.$store.dispatch(MODAL_ACTIONS.OPEN, { modal: MODAL_TYPE.ADD_DEVICE })
  }

}
