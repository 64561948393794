













































import { Component, Prop } from 'vue-property-decorator'
import TableFooter from '@/components/TableFooter.vue'
import { TableColumn } from '@/components/table-column'
import VuexBackedTableBasedView from '@/views/tables/VuexBackedTableBasedView'
import { Device, DeviceType } from '@/api/devices'
import DeviceCabinetIcon from '@/assets/img/device-cabinet-icon.svg'
import { MODAL_ACTIONS, MODAL_TYPE } from '@/store/types'
import { ACTIONS, GETTERS } from '@/store/modules/device-management'
import EditButton from '@/components/EditButton.vue'
import Modal from '@/components/Modal.vue'
import DeviceWizard from '@/components/forms/DeviceWizard.vue'
import { convertToUuidArray } from '@/util'
import ConnectLockGraphic from '@/assets/img/connect-locks-graphic.svg'

@Component({
  components: {
    TableFooter,
    DeviceCabinetIcon,
    EditButton,
    Modal,
    DeviceWizard,
    ConnectLockGraphic,
  }
})
export default class DevicesTable extends VuexBackedTableBasedView<Device> {
  MODAL_TYPE = MODAL_TYPE
  ACTIONS = ACTIONS
  GETTERS = GETTERS
  DeviceType = DeviceType

  @Prop({ default: null }) deviceTypes!: DeviceType[]

  clientIds: string[] = []
  proxyIds: string[] = []
  loading = true

  async mounted(): Promise<void> {
    // Try to filter by clientIds or proxyIds if they're provided, otherwise the backend falls to the devices the user has access to
    this.clientIds = convertToUuidArray(this.$route.query.clientId)
    this.proxyIds = convertToUuidArray(this.$route.query.proxyId)

    await this.$store.dispatch(this.ACTIONS.UPDATE_DEVICE_TYPES, this.deviceTypes)
    await this.loadData(this.clientIds.length > 0 ? this.clientIds : this.proxyIds )
    this.loading = false
  }

  destroyed(): void {
    this.$store.dispatch(ACTIONS.CLEAR)
  }

  editDevice(deviceId: number): void {
    this.$store.dispatch(MODAL_ACTIONS.OPEN, { modal: MODAL_TYPE.EDIT_DEVICE, device: deviceId })
  }

  get columns(): TableColumn<Device>[] {
    return [
      {
        title: 'Name',
        headerColumn: true,
        sticky: true,
        value: (row) => row.name,
        field: 'device.name',
        cellClass: 'selectable',
        headerClass: 'columnMedium',
      },
      {
        title: 'Device ID',
        value: (row) => row.serialNumber,
        field: 'device.serial_number',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'department',
        title: 'Department',
        value: (row) => row.department.name,
        field: 'department.name',
        cellClass: 'selectable',
        headerClass: 'columnMedium'
      },
      {
        title: 'Location',
        value: (row) => row.location as string,
        field: 'device.location',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        title: 'Contact',
        value: (row) => row.contact as string,
        field: 'device.contact',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        title: 'Notes',
        value: (row) => row.note as string,
        field: 'device.note',
        cellClass: 'selectable',
        headerClass: 'columnLarger',
      },
      {
        title: 'Edit',
      }
    ]
  }
}
