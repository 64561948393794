import { Module } from 'vuex'
import { RootState } from '@/store'
import { Auth } from '@aws-amplify/auth'
import users, { User, USER_TYPE } from '@/api/users'
import { ACTIONS as CLIENT_MANAGEMENT_ACTIONS } from '@/store/modules/client-management'
import { ACTIONS as USER_MANAGEMENT_ACTIONS } from '@/store/modules/user-management/website-user-management'
import { ACTIONS as DEVICES_ACTIONS } from '@/store/modules/device-management'
import { ACTIONS as CART_ACTIONS } from '@/store/modules/parts/cart'

export enum USER_ACTIONS {
  LOAD_USER_TYPE = 'UPDATE_ADMIN',
  LOAD_USER = 'UPDATE_USER',
  UPDATE_NAME = 'UPDATE_USER_NAME',
  SIGN_OUT = 'SIGN_OUT',
  BECOME_CLIENT = 'BECOME_CLIENT',
  RETURN_TO_ADMIN = 'RETURN_TO_ADMIN',
}

export enum USER_MUTATIONS {
  UPDATE_USER_TYPE = 'UPDATE_ADMIN',
  UPDATE_USER = 'UPDATE_USER',
  UPDATE_WAS_ADMIN = 'UPDATE_WAS_ADMIN',
}

interface UserState {
  user: User | null,
  userType: USER_TYPE,
  wasAdmin: boolean,
}

export const module: Module<UserState, RootState> = {
  state: {
    user: null,
    userType: USER_TYPE.NOT_SELECTED,
    wasAdmin: false
  },
  getters: {
    user: (state: UserState): User | null => state.user,
    userType: (state: UserState): USER_TYPE => state.userType,
    isAdmin: (state: UserState): boolean => state.userType === USER_TYPE.SOLAIRE_ADMIN,
    isHospitalAdmin: (state: UserState): boolean => state.userType === USER_TYPE.HOSPITAL_ADMIN,
    wasAdmin: (state: UserState) => state.wasAdmin,
  },
  mutations: {
    [USER_MUTATIONS.UPDATE_USER_TYPE]: (state: UserState, userType: USER_TYPE) => {
      state.userType = userType
    },
    [USER_MUTATIONS.UPDATE_USER]: (state: UserState, user: User) => {
      state.user = user
    },
    [USER_MUTATIONS.UPDATE_WAS_ADMIN]: (state: UserState, wasAdmin: boolean) => {
      state.wasAdmin = wasAdmin
    },
  },
  actions: {
    [USER_ACTIONS.LOAD_USER_TYPE]: async ({ commit }) => {
      const session = await Auth.currentSession()
      const userGroups = session.getIdToken().payload['cognito:groups']

      // Determine user type based on cognito groups
      let userType = ''
      if (userGroups.includes('admins')) {
        userType = USER_TYPE.SOLAIRE_ADMIN
      } else if (userGroups.includes('hospital_admin')) {
        userType = USER_TYPE.HOSPITAL_ADMIN
      } else {
        userType = USER_TYPE.DEPARTMENT_USER
      }

      commit(USER_MUTATIONS.UPDATE_USER_TYPE, userType)
    },
    [USER_ACTIONS.LOAD_USER]: async ({ commit }) => {
      try {
        const user = await users.me()
        commit(USER_MUTATIONS.UPDATE_USER, user)
      } catch (error) {
        // If user doesn't exist then just commit a null
        commit(USER_MUTATIONS.UPDATE_USER, null)
      }
    },
    [USER_ACTIONS.UPDATE_NAME]: async ({ commit, state }, { firstName, lastName }: { firstName: string, lastName: string}) => {
      if (state.user) {
        const _firstName = firstName.trim()
        const _lastName = lastName.trim()
        await users.updateName(_firstName, _lastName)
        commit(USER_MUTATIONS.UPDATE_USER, {
          ...state.user,
          first_name: _firstName,
          last_name: _lastName
        } as User)
      }
    },
    [USER_ACTIONS.SIGN_OUT]: async ({ commit, dispatch }) => {
      await Auth.signOut()
      // Clear out store state after signing out
      commit(USER_MUTATIONS.UPDATE_USER, null)
      commit(USER_MUTATIONS.UPDATE_USER_TYPE, USER_TYPE.NOT_SELECTED)
      commit(USER_MUTATIONS.UPDATE_WAS_ADMIN, false)
      await dispatch(CLIENT_MANAGEMENT_ACTIONS.CLEAR)
      await dispatch(USER_MANAGEMENT_ACTIONS.CLEAR)
      await dispatch(DEVICES_ACTIONS.CLEAR)
      await dispatch(CART_ACTIONS.CLEAR)
    },
    [USER_ACTIONS.BECOME_CLIENT]: async ({ commit, dispatch }) => {
      commit(USER_MANAGEMENT_ACTIONS.CLEAR)
      await dispatch(CART_ACTIONS.CLEAR)
    },
    [USER_ACTIONS.RETURN_TO_ADMIN]: async ({ dispatch }) => {
      await dispatch(USER_MANAGEMENT_ACTIONS.CLEAR)
      await dispatch(CLIENT_MANAGEMENT_ACTIONS.CLEAR)
      await dispatch(DEVICES_ACTIONS.CLEAR)
      await dispatch(CART_ACTIONS.CLEAR)
    }
  }
}
