

























import { Component } from 'vue-property-decorator';
import PageBannerHeader from '@/components/PageBannerHeader.vue'
import DataTable from '@/components/DataTable.vue'
import CsvVuexBackedTableBasedView from '@/views/tables/CsvVuexBackedTableBasedView'
import { Cabinet } from '@/api/devices'
import { ACTIONS, GETTERS } from '@/store/modules/reports/drying-unit-reports'
import TableFooter from '@/components/TableFooter.vue'
import DeviceCabinetIcon from '@/assets/img/device-cabinet-icon.svg'
import { TableColumn } from '@/components/table-column'
import DownloadButton from '@/components/DownloadButton.vue'
import SingleselectWrapper from '@/components/forms/SingleselectWrapper.vue'
import MultiselectWrapper from '@/components/forms/MultiselectWrapper.vue'
import DatepickerWrapper from '@/components/forms/Datepicker.vue'
import { convertToUuidArray } from '@/util'

@Component({
  components: {
    PageBannerHeader,
    DataTable,
    TableFooter,
    DeviceCabinetIcon,
    SingleselectWrapper,
    MultiselectWrapper,
    DatepickerWrapper,
    DownloadButton,
  }
})
export default class DryingUnitReportTable extends CsvVuexBackedTableBasedView<Cabinet> {
  GETTERS = GETTERS
  ACTIONS = ACTIONS

  clientIds: string[] = []
  proxyIds: string[] = []

  async mounted(): Promise<void> {
    this.fetchAction = this.ACTIONS.FETCH_LIST
    this.reportName = 'Drying Unit Settings Report.csv'

    // Try to filter by clientIds or proxyIds if they're provided, otherwise the backend falls to the devices the user has access to
    this.clientIds = convertToUuidArray(this.$route.query.clientId)
    this.proxyIds = convertToUuidArray(this.$route.query.proxyId)

    const clientFilterIds = this.clientIds.length > 0 ? this.clientIds : this.proxyIds
    await this.loadData(clientFilterIds)
  }

  get columns(): TableColumn<Cabinet>[] {
    let checkInOutColumns: TableColumn<Cabinet>[] = []
    for (let device of this.results) {
      if (device.cabinetSettings) {
        checkInOutColumns.push(
          ...device.cabinetSettings.checkInFieldsTable.map((checkInField) => {
            return {
              title: `Check In - ${checkInField.display}`,
              value: (row: Cabinet) => row.cabinetSettings?.checkInFieldsTable?.find(field => field.display === checkInField.display)?.visibility ?? '',
              // Removed sorting on these custom fields for now as it wouldn't be reliable
              //field: `cabinet_settings.check_in_fields_table#${index}.visibility`,
              cellClass: 'selectable',
              headerClass: 'columnSmall',
            }
          }),
          ...device.cabinetSettings.checkOutFieldsTable.map((checkOutField) => {
            return {
              title: `Check Out - ${checkOutField.display}`,
              value: (row: Cabinet) => row.cabinetSettings?.checkOutFieldsTable?.find(field => field.display === checkOutField.display)?.visibility ?? '',
              //field: `cabinet_settings.check_out_fields_table#${index}.visibility`,
              cellClass: 'selectable',
              headerClass: 'columnSmall',
            }
          })
        )
      }
    }

    // Get list of titles
    const titles = checkInOutColumns.map(column => column.title)
    // Reassign array with duplicate columns removed
    checkInOutColumns = checkInOutColumns.filter(({ title }, index) => !titles.includes(title, index + 1))

    // Sort by title Check In - X , Check Out - X
    checkInOutColumns.sort((columnA, columnB) => columnA.title.localeCompare(columnB.title))

    // Return base columns then computed checked in/out columns
    const baseColumns = [
      {
        id: 'name',
        title: 'Device Name',
        headerColumn: true,
        sticky: true,
        value: (row: Cabinet) => row.name,
        field: 'device.name',
        cellClass: 'selectable',
        headerClass: 'columnMedium',
      },
      {
        id: 'deviceId',
        title: 'Device ID',
        value: (row: Cabinet) => row.serialNumber,
        field: 'device.serial_number',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'department',
        title: 'Department',
        value: (row: Cabinet) => row.department.name,
        field: 'department.name',
        cellClass: 'selectable',
        headerClass: 'columnMedium'
      },
      {
        id: 'location',
        title: 'Location',
        value: (row: Cabinet) => row.location as string,
        field: 'device.location',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'contact',
        title: 'Contact',
        value: (row: Cabinet) => row.contact as string,
        field: 'device.contact',
        cellClass: 'selectable',
        headerClass: 'columnMedium',
      },
      {
        id: 'notes',
        title: 'Notes',
        value: (row: Cabinet) => row.note as string,
        field: 'device.note',
        cellClass: 'selectable',
        headerClass: 'columnLarger',
      },
      {
        id: 'lastUpdated',
        title: 'Last Updated',
        value: (row: Cabinet) => row.lastUpdated ? new Date(row.lastUpdated).toLocaleString() : '',
        field: 'device.last_updated',
        cellClass: 'selectable',
        headerClass: 'columnMedium',
      },
      {
        id: 'dryingTime',
        title: 'Dry Time',
        value: (row: Cabinet) => `${row.cabinetSettings?.dryingTime?.value ?? ''} ${row.cabinetSettings?.dryingTime?.unit ?? ''}`,
        field: 'cabinet_settings.drying_time.value',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'ventingTime',
        title: 'Vent Time',
        value: (row: Cabinet) => `${row.cabinetSettings?.ventingTime?.value ?? ''} ${row.cabinetSettings?.ventingTime?.unit ?? ''}`,
        field: 'cabinet_settings.venting_time.value',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'ventingInterval',
        title: 'Vent Interval',
        value: (row: Cabinet) => `${row.cabinetSettings?.ventingInterval?.value ?? ''} ${row.cabinetSettings?.ventingInterval?.unit ?? ''}`,
        field: 'cabinet_settings.venting_interval.value',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'expirationTime',
        title: 'Expiration Time',
        value: (row: Cabinet) => `${row.cabinetSettings?.expirationTime?.value ?? ''} ${row.cabinetSettings?.expirationTime?.unit ?? ''}`,
        field: 'cabinet_settings.expiration_time.value',
        cellClass: 'selectable',
        headerClass: 'columnSmall',
      },
      {
        id: 'hoseReplacementDate',
        title: 'Hose Replacement Date',
        value: (row: Cabinet) => {
          return row.cabinetSettings?.cabinetHoseLastReset ? new Date(row.cabinetSettings.cabinetHoseLastReset).toLocaleString() : ''
        },
        field: 'cabinet_settings.cabinet_hose_last_reset',
        cellClass: 'selectable',
        headerClass: 'columnMedium',
      },
      {
        id: 'filterReplacementDate',
        title: 'Filter Replacement Date',
        value: (row: Cabinet) => {
          return row.cabinetSettings?.cabinetFilterLastReset ? new Date(row.cabinetSettings.cabinetFilterLastReset).toLocaleString() : ''
        },
        field: 'cabinet_settings.cabinet_filter_last_reset',
        cellClass: 'selectable',
        headerClass: 'columnMedium',
      }
    ] as TableColumn<Cabinet>[]

    // Check if user has more than 1 client and add a client column if so
    if (this.$store.getters.user?.clients.length > 1) {
      baseColumns.splice(2, 0, {
        id: 'client',
        title: 'Client',
        value: (row: Cabinet) => row.client.name,
        field: 'client.name',
        cellClass: 'selectable',
        headerClass: 'columnMedium'
      })
    }

    return baseColumns.concat(checkInOutColumns)
  }
}
