






















































import { Component } from 'vue-property-decorator'
import UserMenu from '@/components/nav-bar/UserMenu.vue'
import NotificationMenu from '@/components/nav-bar/notifications/NotificationMenu.vue'
import { User } from '@/api/users'
import LogoutIcon from '@img/logout-black.svg'
import NotificationsIcon from '@img/notifications-icon.svg'
import { PAGE_NAMES } from '@/api/misc'
import { ACTIONS as CART_ACTIONS, GETTERS as CART_GETTERS } from '@/store/modules/parts/cart'
import { ACTIONS as NOTIFICATION_ACTIONS, GETTERS as NOTIFICATION_GETTERS } from '@/store/modules/notification-alerts'
import { PAGE_VIEW } from '@/views/order-parts/OrderParts.vue'
import RoleBasedView from '@/views/RoleBasedView'

@Component({
  components: {
    UserMenu,
    NotificationMenu,
    LogoutIcon,
    NotificationsIcon,
  }
})
export default class TopNavBar extends RoleBasedView {
  showUserMenu = false
  showNotificationMenu = false
  isProd = process.env.NODE_ENV === 'production'

  get userInitials(): string {
    const user = (this.$store.getters.user as User | null)
    return (user?.first_name?.charAt(0).toUpperCase() ?? '') + (user?.last_name?.charAt(0).toUpperCase() ?? '')
  }

  get notificationCount(): number {
    return this.$store.getters[NOTIFICATION_GETTERS.NOTIFICATION_COUNT]
  }

  get totalPartCount(): number {
    return this.$store.getters[CART_GETTERS.TOTAL_PART_COUNT]
  }

  get cartOpen(): boolean {
    // Forcefully keep the cart open if on the new order screen
    return this.$route.name === PAGE_NAMES.ORDER_PARTS && this.$route.query.view === PAGE_VIEW.NewOrder || this.$store.getters[CART_GETTERS.CART_OPEN]
  }

  toggleCartOpen(override?: boolean): Promise<void> | undefined {
    // Don't allow toggling the cart open state if we're on the order parts page
    if (this.$route.name !== PAGE_NAMES.ORDER_PARTS || this.$route.query.view !== PAGE_VIEW.NewOrder) {
      return this.$store.dispatch(CART_ACTIONS.TOGGLE_CART_OPEN, override)
    }
  }

  goHome(): void {
    const home = this.isAdmin ? PAGE_NAMES.CLIENT_SUMMARY_DASHBOARD : PAGE_NAMES.DASHBOARD
    if (this.$router.currentRoute.name !== home) {
      this.$router.push({ name: home })
    }
  }

  toggleMenu(): void {
    this.$emit('menu-click')
  }

  goBack(): void {
    this.$router.back()
  }

  toggleNotificationOpen(): void {
    this.showNotificationMenu = !this.showNotificationMenu

    if (!this.showNotificationMenu) {
      // If closing, ensure we call the correct closing process
      this.closeNotificationMenu()
    } else if (this.$store.getters[NOTIFICATION_GETTERS.NOTIFICATION_COUNT] > 0){
      // If opening and we have notifications, mark as seen
      this.$store.dispatch(NOTIFICATION_ACTIONS.UPDATE_NOTIFICATIONS_SEEN, this.$store.getters[NOTIFICATION_GETTERS.LATEST_EVENT])
    }
  }

  closeNotificationMenu(): void {
    this.showNotificationMenu = false

    this.$store.dispatch(NOTIFICATION_ACTIONS.MARK_NOTIFICATIONS_VIEWED)
  }
}
