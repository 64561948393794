












































































































































import { Component, Vue } from 'vue-property-decorator';
import PageBannerHeader from '@/components/PageBannerHeader.vue'
import DataTable from '@/components/DataTable.vue'
import devices, {
  CabinetTransactionFilterOptions,
  DeviceType,
  MAINTENANCE_ACTION_KEYS
} from '@/api/devices'
import { DeviceFilters, SharedDeviceFilters } from '@/store/modules/reports'
import {
  ACTIONS,
  GETTERS,
  MaintenanceLogFilters,
  DRYING_UNIT_REPORT_TYPE,
  ScopeActivityFilters,
  SensorReadingsFilters,
} from '@/store/modules/reports/drying-unit-reports'
import TableFooter from '@/components/TableFooter.vue'
import SingleselectWrapper from '@/components/forms/SingleselectWrapper.vue'
import MultiselectWrapper from '@/components/forms/MultiselectWrapper.vue'
import DatepickerWrapper, { DateRange } from '@/components/forms/Datepicker.vue'
import DryingUnitReportTable from '@/views/reports/drying-units/DryingUnitReportTable.vue'
import ScopeActivityReportTable from '@/views/reports/drying-units/ScopeActivityReportTable.vue'
import MaintenanceLogReportTable from '@/views/reports/drying-units/MaintenanceLogReportTable.vue'
import SensorReadingsReportTable from '@/views/reports/drying-units/SensorReadingsReportTable.vue'
import { SCOPE_STATUS } from '@/api/scope-actions'
import { uuid } from '@/api/models'
import { convertToUuidArray } from '@/util'

@Component({
  components: {
    MaintenanceLogReportTable,
    PageBannerHeader,
    DataTable,
    TableFooter,
    SingleselectWrapper,
    MultiselectWrapper,
    DatepickerWrapper,
    DryingUnitReportTable,
    ScopeActivityReportTable,
    SensorReadingsReportTable,
  }
})
export default class DryingUnitReports extends Vue {
  GETTERS = GETTERS
  ACTIONS = ACTIONS
  DRYING_UNIT_REPORT_TYPE = DRYING_UNIT_REPORT_TYPE

  clientIds: uuid[] = []
  proxyIds: uuid[] = []

  deviceFilterOptions: SharedDeviceFilters | null = null
  transactionFilterOptions: CabinetTransactionFilterOptions | null = null

  async mounted(): Promise<void> {
    // Try to filter by clientIds or proxyIds if they're provided, otherwise the backend falls to the devices the user has access to
    this.clientIds = convertToUuidArray(this.$route.query.clientId)
    this.proxyIds = convertToUuidArray(this.$route.query.proxyId)

    const clientFilterIds = this.clientIds.length > 0 ? this.clientIds : this.proxyIds

    this.deviceFilterOptions = await devices.getDeviceTypeaheadOptions(clientFilterIds, [DeviceType.CABINET])
    this.transactionFilterOptions = await devices.getCabinetTransactionTypeaheadOptions(clientFilterIds)

    // Sort filter options up front due to the large amount of data
    this.sortSharedFilterOptions(this.deviceFilterOptions)
    this.sortTransactionFilterOptions(this.transactionFilterOptions)
  }

  get selectedReportType(): DRYING_UNIT_REPORT_TYPE {
    return this.$store.getters[this.GETTERS.REPORT_TYPE]
  }

  async reportTypeSelected(reportType: DRYING_UNIT_REPORT_TYPE): Promise<void> {
    await this.$store.dispatch(this.ACTIONS.UPDATE_REPORT_TYPE, reportType)
  }

  destroyed(): void {
    this.$store.dispatch(this.ACTIONS.CLEAR)
  }

  updateSharedDeviceFilters(filters: Partial<SharedDeviceFilters>, refreshTable=true): void {
    this.$store.dispatch(this.ACTIONS.UPDATE_SHARED_DEVICE_REPORT_FILTERS, filters)
    if (refreshTable) {
      this.$store.dispatch(this.ACTIONS.REFRESH_TABLE)
    }
  }

  updateDeviceFilters(filters: Partial<DeviceFilters>, refreshTable=true): void {
    this.$store.dispatch(this.ACTIONS.UPDATE_DEVICE_REPORT_FILTERS, filters)
    if (refreshTable) {
      this.$store.dispatch(this.ACTIONS.REFRESH_TABLE)
    }
  }

  updateScopeActivityFilters(filters: Partial<ScopeActivityFilters>, refreshTable=true): void {
    this.$store.dispatch(this.ACTIONS.UPDATE_SCOPE_ACTIVITY_FILTERS, filters)
    if (refreshTable) {
      this.$store.dispatch(this.ACTIONS.REFRESH_TABLE)
    }
  }

  updateMaintenanceFilters(filters: Partial<MaintenanceLogFilters>, refreshTable=true): void {
    this.$store.dispatch(this.ACTIONS.UPDATE_MAINTENANCE_LOG_FILTERS, filters)
    if (refreshTable) {
      this.$store.dispatch(this.ACTIONS.REFRESH_TABLE)
    }
  }

  updateSensorReadingsFilters(filters: Partial<SensorReadingsFilters>, refreshTable=true): void {
    this.$store.dispatch(this.ACTIONS.UPDATE_SENSOR_READINGS_FILTERS, filters)
    if (refreshTable) {
      this.$store.dispatch(this.ACTIONS.REFRESH_TABLE)
    }
  }

  // **************************************************************************
  // Global filters
  // **************************************************************************

  get sharedDeviceFilters(): SharedDeviceFilters {
    return this.$store.getters[this.GETTERS.SHARED_DEVICE_REPORT_FILTERS]
  }

  get deviceNames(): string[] {
    return this.deviceFilterOptions?.deviceNames?.filter(name => name) ?? []
  }

  deviceNamesSelected(deviceNames: string[]): void {
    this.updateSharedDeviceFilters({ deviceNames })
  }

  get deviceSerialNumbers(): string[] {
    return this.deviceFilterOptions?.deviceSerialNumbers?.filter(serialNumber => serialNumber) ?? []
  }

  deviceSerialNumbersSelected(deviceSerialNumbers: string[]): void {
    this.updateSharedDeviceFilters({ deviceSerialNumbers })
  }

  get departmentNames(): string[] {
    return this.deviceFilterOptions?.departmentNames?.filter(department => department) ?? []
  }

  departmentsSelected(departmentNames: string[]): void {
    this.updateSharedDeviceFilters({ departmentNames })
  }

  get deviceLocations(): string[] {
    return this.deviceFilterOptions?.locations?.filter(location => location) ?? []
  }

  deviceLocationsSelected(locations: string[]): void {
    this.updateSharedDeviceFilters({ locations })
  }

  // **************************************************************************
  // CURRENT SETTINGS REPORT FILTERS BELOW
  // **************************************************************************

  get appliedDeviceFilters(): DeviceFilters {
    return this.$store.getters[this.GETTERS.DEVICE_REPORT_FILTERS]
  }

  // Contact filters
  get contacts(): string[] {
    return this.deviceFilterOptions?.contacts?.filter(contact => contact) ?? []
  }

  contactsSelected(contacts: string[]): void {
    this.updateSharedDeviceFilters({ contacts })
  }

  // Last updated date range filter
  get currentSettingsLastUpdatedRange(): DateRange | null {
    const lastUpdatedStart = this.appliedDeviceFilters.lastUpdatedStart
    const lastUpdatedEnd = this.appliedDeviceFilters.lastUpdatedEnd
    if (lastUpdatedStart && lastUpdatedEnd) {
      return { start: lastUpdatedStart, end: lastUpdatedEnd }
    }
    return null
  }

  currentSettingsLastUpdatedRangeChanged(range: { start: Date, end: Date } | null): void {
    this.updateDeviceFilters({ lastUpdatedStart: range?.start, lastUpdatedEnd: range?.end })
  }

  // **************************************************************************
  // SCOPE ACTIVITY REPORT FILTERS BELOW
  // **************************************************************************

  get appliedScopeActivityFilters(): ScopeActivityFilters {
    return this.$store.getters[this.GETTERS.SCOPE_ACTIVITY_FILTERS]
  }

  // Timestamp
  get eventTimestampRange(): DateRange | null {
    const timestampStart = this.appliedScopeActivityFilters.timestampStart
    const timestampEnd = this.appliedScopeActivityFilters.timestampEnd
    if (timestampStart && timestampEnd) {
      return { start: timestampStart, end: timestampEnd }
    }
    return null
  }

  eventTimestampRangeChanged(range: { start: Date, end: Date } | null): void {
    this.updateScopeActivityFilters({ timestampStart: range?.start, timestampEnd: range?.end })
  }

  // Brands
  get brandOptions(): string[] {
    return this.transactionFilterOptions?.brands?.filter(brand => brand) ?? []
  }

  brandsSelected(brands: string[]): void {
    this.updateScopeActivityFilters({ brands })
  }

  // Model Numbers
  get modelNumberOptions(): string[] {
    return this.transactionFilterOptions?.modelNumbers?.filter(number => number) ?? []
  }

  modelNumbersSelected(modelNumbers: string[]): void {
    this.updateScopeActivityFilters({ modelNumbers })
  }

  // Internal IDs
  get internalIdOptions(): string[] {
    return this.transactionFilterOptions?.internalIds?.filter(id => id) ?? []
  }

  internalIdsSelected(internalIds: string[]): void {
    this.updateScopeActivityFilters({ internalIds })
  }

  // Case numbers
  get caseNumberOptions(): string[] {
    return this.transactionFilterOptions?.caseNumbers?.filter(number => number) ?? []
  }

  caseNumbersSelected(caseNumbers: string[]): void {
    this.updateScopeActivityFilters({ caseNumbers })
  }

  // Endoscopist names
  get endoscopistNameOptions(): string[] {
    return this.transactionFilterOptions?.endoscopistNames?.filter(name => name) ?? []
  }

  endoscopistNamesSelected(endoscopistNames: string[]): void {
    this.updateScopeActivityFilters({ endoscopistNames })
  }

  // Actions
  get actionOptions(): string[] {
    return SCOPE_STATUS.ALL_ACTIONS
  }

  actionsSelected(actions: string[]): void {
    this.updateScopeActivityFilters({ actions })
  }

  // Users
  get userOptions(): string[] {
    return this.transactionFilterOptions?.users?.filter(user => user) ?? []
  }

  usersSelected(users: string[]): void {
    this.updateScopeActivityFilters({ users })
  }

  // **************************************************************************
  // MAINTENANCE LOG REPORTS BELOW
  // **************************************************************************

  get appliedMaintenanceLogFilters(): MaintenanceLogFilters {
    return this.$store.getters[this.GETTERS.MAINTENANCE_LOG_FILTERS]
  }

  // Action (AKA event) filter
  get actionOptionsForMaintenance(): { key: string, value: string }[] {
    return Object.keys(MAINTENANCE_ACTION_KEYS).map(key =>
      ({
        key,
        value: MAINTENANCE_ACTION_KEYS[key]
      })
    )
  }

  get selectedActionsForMaintenance(): { key: string, value: string }[] {
    return this.appliedMaintenanceLogFilters.actions.map(action =>
      ({
        key: action,
        value: MAINTENANCE_ACTION_KEYS[action]
      })
    )
  }

  actionsSelectedForMaintenance(actions: { key: string, value: string }[]): void {
    this.updateMaintenanceFilters({ actions: actions.map(action => action.key) })
  }

  // Timestamp filter
  get maintenanceTimestampRange(): DateRange | null {
    const timestampStart = this.appliedMaintenanceLogFilters.timestampStart
    const timestampEnd = this.appliedMaintenanceLogFilters.timestampEnd
    if (timestampStart && timestampEnd) {
      return { start: timestampStart, end: timestampEnd }
    }
    return null
  }

  maintenanceTimestampRangeChanged(range: { start: Date, end: Date } | null): void {
    this.updateMaintenanceFilters({ timestampStart: range?.start, timestampEnd: range?.end })
  }

  // **************************************************************************
  // CABINET SENSOR READING REPORTS BELOW
  // **************************************************************************

  get appliedSensorReadingsFilters(): SensorReadingsFilters {
    return this.$store.getters[this.GETTERS.MAINTENANCE_LOG_FILTERS]
  }

  get sensorReadingsTimestampRange(): DateRange | null {
    const timestampStart = this.appliedSensorReadingsFilters.sensorDataDateStart
    const timestampEnd = this.appliedSensorReadingsFilters.sensorDataDateEnd
    if (timestampStart && timestampEnd) {
      return { start: timestampStart, end: timestampEnd }
    }
    return null
  }

  sensorReadingsTimestampRangeChanged(range: { start: Date, end: Date } | null): void {
    this.updateSensorReadingsFilters({ sensorDataDateStart: range?.start, sensorDataDateEnd: range?.end })
  }

  private sortSharedFilterOptions(filterOptions: SharedDeviceFilters) {
    if (filterOptions) {
      filterOptions.contacts.sort()
      filterOptions.deviceNames.sort()
      filterOptions.deviceSerialNumbers.sort()
      filterOptions.departmentNames.sort()
      filterOptions.locations.sort()
    }
  }

  private sortTransactionFilterOptions(filterOptions: CabinetTransactionFilterOptions) {
    if (filterOptions) {
      filterOptions.internalIds?.sort()
      filterOptions.serialNumbers?.sort()
      filterOptions.modelNumbers?.sort()
      filterOptions.brands?.sort()
      filterOptions.users?.sort()
      filterOptions.endoscopistNames?.sort()
      filterOptions.caseNumbers?.sort()
    }
  }
}
