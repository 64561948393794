















import { Component, Vue } from 'vue-property-decorator'
import TopNavBar from '@/router/TopNavBar.vue'
import SideNavBar from '@/router/SideNavBar.vue'

// Svelte Components
import '@solaire-medical/svelte-component-library/dist/index.esm.js'

@Component({
  components: {
    TopNavBar,
    SideNavBar,
  },
})
export default class App extends Vue {
  menuOpen = true;
  isSignedIn(): boolean {
    return this.$route.name !== 'Sign In'
  }
  menuClick(): void {
    this.menuOpen = !this.menuOpen;
  }
}
