import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import Dashboard from '@/views/dashboard/Dashboard.vue'
import Reports from '@/views/reports/Reports.vue'
import ClientSummaryDashboard from '@/views/ClientSummaryDashboard.vue'
import OrderParts from '@/views/order-parts/OrderParts.vue'
import ManageDevices from '@/views/manage-devices/ManageDevices.vue'
import ClientManagement from '@/views/ClientManagement.vue'
import UserManagement from '@/views/user-management/UserManagement.vue'
import WebsiteUsersTable from '@/views/user-management/WebsiteUserTable.vue'
import SignIn from '@/views/SignIn.vue'
import NotFound from '@/views/NotFound.vue'
import Unauthorized from '@/views/Unauthorized.vue'
import ClientData from '@/views/client-view/ClientData.vue'
import ClientDevices from '@/views/client-view/ClientDevices.vue'
import ClientSubscriptions from '@/views/client-view/ClientSubscriptions.vue'
import CabinetDevices from '@/views/manage-devices/children/CabinetDevices.vue'
import LockDevices from '@/views/manage-devices/children/LockDevices.vue'
import { Auth } from '@aws-amplify/auth'
import { Hub } from '@aws-amplify/core'
import store from '@/store/index'
import { USER_TYPE } from '@/api/users'
import { USER_ACTIONS } from '@/store/modules/user'
import DryingUnitReports from '@/views/reports/drying-units/DryingUnitReports.vue'
import InterConnectLockReports from '@/views/reports/inter-connect-locks/InterConnectLockReports.vue'
import { PAGE_NAMES, PROXY_ID } from '@/api/misc'
import NotificationManagement from '@/views/notification-management/NotificationManagement.vue'
import CabinetNotificationTable from '@/views/notification-management/CabinetNotificationTable.vue'
import DryingUnitDashboard from '@/views/dashboard/DryingUnitDashboard.vue'
import InterConnectLockDashboard from '@/views/dashboard/InterConnectLockDashboard.vue'
import InterConnectLockNotificationTable from '@/views/notification-management/InterConnectLockNotificationTable.vue'

Vue.use(VueRouter)

const DEFAULT_ROUTE_PATH = '/'
const SIGN_IN_PATH = '/signin'

const CLIENT_DRILL_DOWN_VIEWS = [
  PAGE_NAMES.CLIENT_SUBSCRIPTIONS,
  PAGE_NAMES.CLIENT_DEVICES
]

const isImpersonatingClient = (to: Route, from: Route, userType: USER_TYPE): boolean => {
  return (!!to.query[PROXY_ID] || !!from.query[PROXY_ID]) && userType == USER_TYPE.SOLAIRE_ADMIN
}

const canUserAccessPage = (to: Route, userType: USER_TYPE): boolean => {
  return (!to.meta?.requiredAccess) || to.meta?.requiredAccess?.includes(userType)
}

const pageIsOnlySolaireAdmin = (to: Route): boolean  => {
  return to.meta?.requiredAccess?.includes(USER_TYPE.SOLAIRE_ADMIN) && to.meta?.requiredAccess?.length === 1
}

const isViewingClient = (to: Route, from: Route): boolean => {
  return !!(!to.query['clientId'] && from.query['clientId'])
}

const routes: Array<RouteConfig> = [
  {
    path: DEFAULT_ROUTE_PATH,
    redirect: '/dashboard',
  },
  {
    path: DEFAULT_ROUTE_PATH + 'dashboard/',
    name: PAGE_NAMES.DASHBOARD,
    component: Dashboard,
    redirect: (to) => {
      const endosopyRedirect = { name: PAGE_NAMES.DRYING_UNIT_DASHBOARD };
      const securityRedirect = { name: PAGE_NAMES.SMART_LOCK_DASHBOARD };
      if (to.query.eco === "endoscopy") {
        return endosopyRedirect;
      } else if (to.query.eco === "security") {
        return securityRedirect
      } else {
        return endosopyRedirect;
      }
    },
    meta: {
      title: PAGE_NAMES.DASHBOARD
    },
    children: [
      {
        path: 'drying-units/',
        name: PAGE_NAMES.DRYING_UNIT_DASHBOARD,
        component: DryingUnitDashboard,
        meta: {
          title: PAGE_NAMES.DRYING_UNIT_DASHBOARD,
        },
      },
      {
        path: 'inter-connect-locks/',
        name: PAGE_NAMES.SMART_LOCK_DASHBOARD,
        component: InterConnectLockDashboard,
        meta: {
          title: PAGE_NAMES.SMART_LOCK_DASHBOARD,
        },
      },
    ],
    beforeEnter: (to, from, next) => {
      if (store.getters.userType === USER_TYPE.SOLAIRE_ADMIN && !to.query[PROXY_ID]) {
        next({ path: 'client-summary' })
      } else {
        next()
      }
    },
  },
  {
    path: DEFAULT_ROUTE_PATH + 'client-summary',
    name: PAGE_NAMES.CLIENT_SUMMARY_DASHBOARD,
    component: ClientSummaryDashboard,
    meta: {
      title: PAGE_NAMES.DASHBOARD, // This is to make both pages seems like the same dashboard
      requiredAccess: [
        USER_TYPE.SOLAIRE_ADMIN,
      ]
    },
  },
  {
    path: SIGN_IN_PATH,
    name: 'Sign In',
    component: SignIn,
  },
  {
    path: DEFAULT_ROUTE_PATH + 'reports/',
    name: PAGE_NAMES.REPORTS,
    redirect: (to) => {
      const endosopyRedirect = { name: PAGE_NAMES.DRYING_UNIT_REPORTS };
      const securityRedirect = { name: PAGE_NAMES.SMART_LOCK_REPORTS };
      if (to.query.eco === "endoscopy") {
        return endosopyRedirect;
      } else if (to.query.eco === "security") {
        return securityRedirect
      } else {
        return endosopyRedirect;
      }
    },
    component: Reports,
    meta: {
      title: PAGE_NAMES.REPORTS,
      requiredAccess: [
        USER_TYPE.DEPARTMENT_USER,
        USER_TYPE.HOSPITAL_ADMIN
      ]
    },
    children: [
      {
        path: 'drying-units/',
        name: PAGE_NAMES.DRYING_UNIT_REPORTS,
        component: DryingUnitReports,
        meta: {
          title: PAGE_NAMES.DRYING_UNIT_REPORTS,
          requiredAccess: [
            USER_TYPE.DEPARTMENT_USER,
            USER_TYPE.HOSPITAL_ADMIN
          ]
        },
      },
      {
        path: 'inter-connect-locks/',
        name: PAGE_NAMES.SMART_LOCK_REPORTS,
        component: InterConnectLockReports,
        meta: {
          title: PAGE_NAMES.SMART_LOCK_REPORTS,
          requiredAccess: [
            USER_TYPE.DEPARTMENT_USER,
            USER_TYPE.HOSPITAL_ADMIN
          ]
        },
      },
    ]
  },
  {
    path: DEFAULT_ROUTE_PATH + 'order-parts',
    name: PAGE_NAMES.ORDER_PARTS,
    component: OrderParts,
    meta: {
      title: PAGE_NAMES.ORDER_PARTS,
      requiredAccess: [
        USER_TYPE.HOSPITAL_ADMIN,
        USER_TYPE.SOLAIRE_ADMIN,
      ]
    },
  },
  {
    path: DEFAULT_ROUTE_PATH + 'settings/notifications/',
    name: PAGE_NAMES.NOTIFICATIONS,
    redirect: (to) => {
      const endosopyRedirect = { name: PAGE_NAMES.NOTIFICATIONS_CABINETS };
      const securityRedirect = { name: PAGE_NAMES.NOTIFICATIONS_LOCKS };
      if (to.query.eco === "endoscopy") {
        return endosopyRedirect;
      } else if (to.query.eco === "security") {
        return securityRedirect
      } else {
        return endosopyRedirect;
      }
    },
    component: NotificationManagement,
    meta: {
      title: PAGE_NAMES.NOTIFICATIONS,
      requiredAccess: [
        USER_TYPE.HOSPITAL_ADMIN,
        USER_TYPE.DEPARTMENT_USER,
      ]
    },
    children: [
      {
        path: 'cabinets/',
        name: PAGE_NAMES.NOTIFICATIONS_CABINETS,
        component: CabinetNotificationTable,
        meta: {
          title: PAGE_NAMES.NOTIFICATIONS_CABINETS,
          requiredAccess: [
            USER_TYPE.HOSPITAL_ADMIN,
            USER_TYPE.DEPARTMENT_USER
          ],
        },
      },
      {
        path: 'inter-connect-locks/',
        name: PAGE_NAMES.NOTIFICATIONS_LOCKS,
        component: InterConnectLockNotificationTable,
        meta: {
          title: PAGE_NAMES.NOTIFICATIONS_LOCKS,
          requiredAccess: [
            USER_TYPE.HOSPITAL_ADMIN,
            USER_TYPE.DEPARTMENT_USER
          ],
        },
      }
    ]
  },
  {
    path: DEFAULT_ROUTE_PATH + 'settings/manage-devices/',
    name: PAGE_NAMES.MANAGE_DEVICES,
    redirect: (to) => {
      const endosopyRedirect = { name: PAGE_NAMES.CABINET_DEVICES };
      const securityRedirect = { name: PAGE_NAMES.LOCK_DEVICES };
      if (to.query.eco === "endoscopy") {
        return endosopyRedirect;
      } else if (to.query.eco === "security") {
        return securityRedirect
      } else {
        return endosopyRedirect;
      }
    },
    component: ManageDevices,
    meta: {
      title: PAGE_NAMES.MANAGE_DEVICES,
      requiredAccess: [
        USER_TYPE.HOSPITAL_ADMIN,
      ]
    },
    children: [
      {
        path: 'cabinets/',
        name: PAGE_NAMES.CABINET_DEVICES,
        component: CabinetDevices,
        meta: {
          title: PAGE_NAMES.CABINET_DEVICES,
          requiredAccess: [
            USER_TYPE.HOSPITAL_ADMIN,
          ],
        },
      },
      {
        path: 'locks/',
        name: PAGE_NAMES.LOCK_DEVICES,
        component: LockDevices,
        meta: {
          title: PAGE_NAMES.LOCK_DEVICES,
          requiredAccess: [
            USER_TYPE.HOSPITAL_ADMIN,
          ],
        },
      },
    ]
  },
  {
    path: DEFAULT_ROUTE_PATH + 'settings/client-management/',
    name: PAGE_NAMES.CLIENT_MANAGEMENT,
    component: ClientManagement,
    meta: {
      title: PAGE_NAMES.CLIENT_MANAGEMENT,
      requiredAccess: [
        USER_TYPE.SOLAIRE_ADMIN,
      ]
    },
  },
  {
    path: DEFAULT_ROUTE_PATH + 'settings/client-management/data/',
    name: PAGE_NAMES.CLIENT_DATA,
    component: ClientData,
    meta: {
      title: PAGE_NAMES.CLIENT_DATA,
      requiredAccess: [
        USER_TYPE.SOLAIRE_ADMIN,
      ]
    },
    children: [
      {
        path: 'client-devices/',
        name: PAGE_NAMES.CLIENT_DEVICES,
        component: ClientDevices,
        meta: {
          title: PAGE_NAMES.CLIENT_DEVICES,
          requiredAccess: [
            USER_TYPE.SOLAIRE_ADMIN,
          ],
        },
      },
      {
        path: 'client-subscriptions/',
        name: PAGE_NAMES.CLIENT_SUBSCRIPTIONS,
        component: ClientSubscriptions,
        meta: {
          title: PAGE_NAMES.CLIENT_SUBSCRIPTIONS,
          requiredAccess: [
            USER_TYPE.SOLAIRE_ADMIN,
          ],
        },
      },
    ]
  },
  {
    path: DEFAULT_ROUTE_PATH + 'settings/user-management/',
    name: PAGE_NAMES.USER_MANAGEMENT,
    redirect: { name: PAGE_NAMES.WEBSITE_USERS },
    component: UserManagement,
    meta: {
      title: PAGE_NAMES.USER_MANAGEMENT,
      requiredAccess: [
        USER_TYPE.HOSPITAL_ADMIN,
      ],
    },
    children: [
      {
        path: 'website-users/',
        name: PAGE_NAMES.WEBSITE_USERS,
        component: WebsiteUsersTable,
        meta: {
          title: PAGE_NAMES.WEBSITE_USERS,
          requiredAccess: [
            USER_TYPE.HOSPITAL_ADMIN,
          ],
        },
      },
      {
        path: 'device-users/',
        name: PAGE_NAMES.DEVICE_USERS,
        meta: {
          title: PAGE_NAMES.DEVICE_USERS
        }
      },
    ]
  },
  {
    path: DEFAULT_ROUTE_PATH + 'unauthorized/',
    name: PAGE_NAMES.UNAUTHORIZED,
    component: Unauthorized,
    meta: {
      title: PAGE_NAMES.UNAUTHORIZED,
    }
  },
  {
    path: '*',
    component: NotFound,
    name: PAGE_NAMES.NOT_FOUND,
  },
]

const router = new VueRouter({
  // https://cli.vuejs.org/guide/mode-and-env.html#environment-variables
  base: process.env.BASE_URL,
  // Cloudfront can't support history mode at subdirectories
  mode: process.env.BASE_URL === '/' ? 'history' : 'hash',
  routes
})

const navigateToLandingPage = (eco?: string | null) => {
  let next = router.currentRoute.query.next as string
  if (eco) {
    router.currentRoute.query.eco = eco
    next =  `/dashboard?eco=${eco}`; 
  }
  if (!next) {
    next = '/dashboard'
  }

  router.replace(next).catch(() => { return })
}

// Protect all pages besides signin behind authentication requirement
router.beforeEach(async (to, _from, next) => {
  if (to.path === SIGN_IN_PATH) {
    try {
      await Auth.currentAuthenticatedUser()
      navigateToLandingPage(to.query.eco as string | null)
    } catch (error) {
      // If the user is not logged in, and they want to go to signin, let them
      document.title = to.meta.title || to.meta.name || 'Smart Solutions'
      next()
    }
  } else {
    try {
      await Auth.currentAuthenticatedUser()

      // If either are empty, reload them back to state
      if (store.getters.userType === USER_TYPE.NOT_SELECTED && store.getters.user == null) {
        await store.dispatch(USER_ACTIONS.LOAD_USER)
        await store.dispatch(USER_ACTIONS.LOAD_USER_TYPE)
      }
      const userType = store.getters.userType

      document.title = to.meta.title ||  to.meta.name || 'Smart Solutions'

      if (to.query[PROXY_ID]) {
        if (userType === USER_TYPE.SOLAIRE_ADMIN) {
          next()
        } else {
          delete to.query[PROXY_ID]
          next({ path: to.path })
        }
      } else {
        if (!canUserAccessPage(to, userType) && !isImpersonatingClient(to, _from, userType)) {
          next({ path: '/unauthorized' })
        } else if (isImpersonatingClient(to, _from, userType) && !pageIsOnlySolaireAdmin(to)) {
          const queryParams = Object.assign(to.query, { [PROXY_ID]: _from.query[PROXY_ID] })
          next({ path: to.path, query: queryParams })
        } else if (pageIsOnlySolaireAdmin(to) && isViewingClient(to, _from) && CLIENT_DRILL_DOWN_VIEWS.includes(to.name ?? '')) {
          const queryParams = Object.assign(to.query, { clientId: _from.query['clientId'] })
          next({ path: to.path, query: queryParams })
        } else {
          next()
        }
      }
    } catch (error) {
      next({ path: `${SIGN_IN_PATH}?next=${encodeURIComponent(to.fullPath)}` })
    }
  }
})

// Send user to login page when they log out
Hub.listen("auth", ({ payload: { event } }) => {
  if (event === "signOut") {
    router.replace({ path: SIGN_IN_PATH })
  } else if (event === "signIn") {
    navigateToLandingPage()
  }
});


export default router
